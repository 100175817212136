import React from 'react'
import { parseISO } from 'date-fns'
import useFormatDate from '../hooks/useFormatDate'

const Date = ({
    inTimeZone,
    dateString = null,
    format = 'dd MMM',
    ...props
}) => {
    const formatFn = useFormatDate({ inTimeZone })
    if (dateString === null) {
        return ''
    }
    return (
        <span {...props}>
            {formatFn(parseISO(dateString), format)}
        </span>
    )
}

export default Date
